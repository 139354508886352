import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Card, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../config';
import ConfirmationModal from './ConfirmationModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Account = () => {
  const [accounts, setAccounts] = useState([]);
  const [accountSubCategories, setAccountSubCategories] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    accountName: '',
    accountSubCategoryId: '',
    accountTypeId: '',
    balance: '',
    accountStatus: '0'
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchAccounts();
    fetchAccountSubCategories();
    fetchAccountTypes();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/account/list-accounts`);
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setToastMessage('Error fetching accounts');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const fetchAccountSubCategories = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/accountsubcategory/list-account-subcategories`);
      setAccountSubCategories(response.data);
    } catch (error) {
      console.error('Error fetching account subcategories:', error);
      setToastMessage('Error fetching account subcategories');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const fetchAccountTypes = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/accounttype/list-account-type`);
      setAccountTypes(response.data);
    } catch (error) {
      console.error('Error fetching account types:', error);
      setToastMessage('Error fetching account types');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedAccountId(id);
    setModalBody('Are you sure you want to delete this account?');
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmationModal(false);
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/account/${selectedAccountId}`);
      if (response.status === 200) {
        setToastMessage(response.data.message);
        setToastType('success');
        setShowToast(true);
        fetchAccounts();
      } else {
        setToastMessage(response.data.message);
        setToastType('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setToastMessage('Error deleting account');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateOrUpdateAccount = async () => {
    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${config.API_BASE_URL}/account/${selectedAccountId}`, formData);
      } else {
        response = await axios.post(`${config.API_BASE_URL}/account`, formData);
      }

      if (response.status === 200) {
        setToastMessage(response.data.message);
        setToastType('success');
        setShowToast(true);
        fetchAccounts();
        setShowEditModal(false);
      }
    } catch (error) {
        console.error('Error creating or updating account:', error);
      
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setToastMessage(error.response.data.message);
          setToastType('danger');
          setShowToast(true);
        } else if (error.request) {
          // The request was made but no response was received
          setToastMessage('No response received from server');
          setToastType('danger');
          setShowToast(true);
        } else {
          // Something happened in setting up the request that triggered an Error
          setToastMessage('Error creating or updating account. : ' + error.message);
          setToastType('danger');
          setShowToast(true);
        }
      }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = (account) => {
    setFormData({
      accountName: account.accountName,
      accountSubCategoryId: account.accountSubCategoryId.toString(),
      accountTypeId: account.accountTypeId.toString(),
      balance: account.balance.toString(),
      accountStatus: account.accountStatus.toString()
    });
    setSelectedAccountId(account.id);
    setIsEditing(true);
    setShowEditModal(true);
  };

  const handleNewClick = () => {
    setFormData({
      accountName: '',
      accountSubCategoryId: '',
      accountTypeId: '',
      balance: '',
      accountStatus: '0'
    });
    setIsEditing(false);
    setShowEditModal(true);
  };

  const filteredAccounts = accounts.filter(account =>
    account.accountName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <div className="row mb-3">
        <div className="col-12 d-flex justify-content-between align-items-center top-header">
          <h4>Accounts</h4>
          <div className="icon-container">
            <Dropdown align="end" className="user-dropdown">
              <Dropdown.Toggle as="div" className="user-icon">
                <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon icon={faCog} size="lg" className="icon ml-3" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <Form.Control
          type="text"
          placeholder="Search accounts..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-75"
        />
        <Button variant="success" onClick={handleNewClick} className="ml-3">
          <FontAwesomeIcon icon={faPlus} /> New
        </Button>
      </div>
      <Card>
        <Card.Body>
          {filteredAccounts.length > 0 ? (
            <Table bordered striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Account Name</th>
                  <th>Sub Category</th>
                  <th>Account Type</th>
                  <th>Balance</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredAccounts.map((account, index) => (
                  <tr key={account.id}>
                    <td>{index + 1}</td>
                    <td>{account.accountName}</td>
                    <td>{account.accountSubCategoryName}</td>
                    <td>{account.accountTypeName}</td>
                    <td>{account.balance}</td>
                    <td>
                      <Button className={`btn btn-sm ${account.accountStatus === 0 ? 'btn-success' : 'btn-danger'}`}>
                        {account.accountStatus === 0 ? 'Active' : 'Inactive'}
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="btn btn-primary btn-sm mr-2"
                        onClick={() => handleEditClick(account)}
                      >
                        Edit
                      </Button>
                      <Button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDeleteClick(account.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">No accounts found</p>
          )}
        </Card.Body>
      </Card>

      {/* Edit Account Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Account' : 'Add New Account'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleCreateOrUpdateAccount(); }}>
            <Form.Group controlId="accountName" className="mt-3">
              <Form.Control
                type="text"
                name="accountName"
                placeholder="Account Name"
                value={formData.accountName}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="accountSubCategoryId" className="mt-3">
              <Form.Control
                as="select"
                name="accountSubCategoryId"
                value={formData.accountSubCategoryId}
                onChange={handleFormChange}
                required
              >
                <option value="">Select Sub Category</option>
                {accountSubCategories.map(subCategory => (
                  <option key={subCategory.id} value={subCategory.id}>
                    {subCategory.accountSubCategoryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="accountTypeId" className="mt-3">
              <Form.Control
                as="select"
                name="accountTypeId"
                value={formData.accountTypeId}
                onChange={handleFormChange}
                required
              >
                <option value="">Select Account Type</option>
                {accountTypes.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.accountTypeName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="balance" className="mt-3">
              <Form.Control
                type="number"
                name="balance"
                placeholder="Balance"
                value={formData.balance}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="accountStatus" className="mt-3">
              <Form.Control
                as="select"
                name="accountStatus"
                value={formData.accountStatus}
                onChange={handleFormChange}
                required
              >
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
            <div className="mt-3 d-flex justify-content-end gap-2">
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
              <Button variant="success" type="submit">{isEditing ? 'Save' : 'Create'}</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmDelete}
        modalBody={modalBody} // Dynamic modal body content
      />
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
        {showToast && (
          <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {toastMessage}
              </div>
              <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
            </div>
            <div className="progress" style={{ height: '5px' }}>
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;