import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from './static/images/logo.png';

const Signup = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        address: '',
        password: '',
        confirmPassword: '' // Added confirmPassword field
    });
    const [error, setError] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Password and Confirm Password validation
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/user`, {
                fullName: formData.fullName,
                email: formData.email,
                phoneNumber: formData.phoneNumber,
                address: formData.address,
                password: formData.password
            });

            if (response.status === 200 || response.status === 201) {
                setToastMessage('Signup successful!');
                setToastType('success');
                setShowToast(true);
                setTimeout(() => navigate('/user/login'), 2000);
            } else {
                const errorMessage = response.data.message || 'An error occurred. Please try again.';
                setToastMessage(errorMessage);
                setToastType('danger');
                setShowToast(true);
            }
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = error.response.data.message || 'An error occurred. Please try again.';
            setToastMessage(errorMessage);
            setToastType('danger');
            setShowToast(true);
        }
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-left">
                    <div className="logo">
                        <img src={logo} alt="SK ELECTRONICS" />
                    </div>
                    <div className="about-company">
                                Welcome to Saleem Khan Electronics! Join us today and be part of a community that values innovation, quality, and customer satisfaction.
                                We're excited to have you on board. Please fill out the form on the right to get started.
                    </div>
                </div>
                <div className="login-right">
                    <h2>Signup</h2>
                    <form onSubmit={handleSubmit} className="w-100">
                        <div className="mb-3">
                            <input type="text" className="form-control" id="fullName" name="fullName" placeholder='Full Name' value={formData.fullName} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="email" className="form-control" id="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" placeholder='Phone Number' value={formData.phoneNumber} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="text" className="form-control" id="address" name="address" placeholder='Address' value={formData.address} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="form-control" id="password" name="password" placeholder='Password' value={formData.password} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="form-control" id="confirmPassword" name="confirmPassword" placeholder='Confirm Password' value={formData.confirmPassword} onChange={handleChange} required />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button type="submit" className="btn btn-success w-100">Signup</button>
                    </form>
                </div>
            </div>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
                {showToast && (
                    <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body">
                                {toastMessage}
                            </div>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                        </div>
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Signup;
