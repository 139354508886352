import React, { Component, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import logo from './static/images/logo.png';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('');
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${config.API_BASE_URL}/user/login`, {
                email: formData.email,
                password: formData.password
            });

            if (response.status === 200) {
                const authToken = response.data.authToken; // Assuming the API returns a sessionId
                const expirationTime = new Date().getTime() + (20 * 60 * 1000); // 20 minutes from now
                
                localStorage.setItem('authToken', authToken);
                localStorage.setItem('sessionExpiration', expirationTime.toString());

                setToastMessage(response.data.message);
                setToastType('success');
                setShowToast(true);
                setTimeout(() => navigate('/dashboard'), 2000);
            } else {
                setToastMessage(response.data.message);
                setToastType('danger');
                setShowToast(true);
            }
        } catch (error) {
            console.error('An unexpected error occurred. ', error);
          
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              setToastMessage(error.response.data.message);
              setToastType('danger');
              setShowToast(true);
            } else if (error.request) {
              // The request was made but no response was received
              setToastMessage('No response received from server');
              setToastType('danger');
              setShowToast(true);
            } else {
              // Something happened in setting up the request that triggered an Error
              setToastMessage('Error while login : ' + error.message);
              setToastType('danger');
              setShowToast(true);
            }
          }
    };

    return (
        <div className="login-container">
            <div className="login-content">
                <div className="login-left">
                    <div className="logo">
                        <img src={logo} alt="SK ELECTRONICS" />
                    </div>
                    <div className="about-company">Welcome to Saleem Khan Electronics, your trusted partner in cutting-edge electronic solutions. At Saleem Khan Electronics, we are committed to providing you with top-quality products and exceptional service.</div>
                </div>
                <div className="login-right">
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <input type="email" className="form-control" id="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="form-control" id="password" name="password" placeholder='Password' value={formData.password} onChange={handleChange} required />
                        </div>
                        {error && <div className="alert alert-danger">{error}</div>}
                        <button type="submit" className="btn btn-success w-100">Login</button>
                    </form>
                    <div className="signup-link">
                        Don't have an account? <a href="/user">Click here to signup</a>
                    </div>
                </div>
            </div>
            <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
                {showToast && (
                    <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex">
                            <div className="toast-body">
                                {toastMessage}
                            </div>
                            <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
                        </div>
                        <div className="progress" style={{ height: '5px' }}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
