import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Signup from './components/Signup';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Product from './components/Product';
import Warehouse from './components/Warehouse';
import Category from './components/Category';
import Company from './components/Company';
import AccountCategory from './components/AccountCategory';
import AccountSubCategory from './components/AccountSubCategory';
import AccountType from './components/AccountType';
import Account from './components/Account';
import { SideNavbar } from './components/navbar/Navbar';
import './App.css'

function AppContent() {
  const location = useLocation();
  const showNavbar = !['/user', '/user/login'].includes(location.pathname);
  const appClasses = showNavbar ? 'App' : 'App-no-navbar';

  return (
    <div className={appClasses}>
      <div className="content">
        {showNavbar && <SideNavbar />}
        <main>
          <Routes>
            <Route path="/user" element={<Signup />} />
            <Route path="/user/login" element={<Login />} />
            
            {/* Redirect root to dashboard */}
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            
            {/* Protected routes */}
            <Route path="/dashboard" element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path="/product" element={
              <PrivateRoute>
                <Product />
              </PrivateRoute>
            } />
            <Route path="/company" element={
              <PrivateRoute>
                <Company />
              </PrivateRoute>
            } />
            <Route path="/warehouse" element={
              <PrivateRoute>
                <Warehouse />
              </PrivateRoute>
            } />
            <Route path="/category" element={
              <PrivateRoute>
                <Category />
              </PrivateRoute>
            } />
            <Route path="/account-category" element={
              <PrivateRoute>
                <AccountCategory />
              </PrivateRoute>
            } />
            <Route path="/account-sub-category" element={
              <PrivateRoute>
                <AccountSubCategory />
              </PrivateRoute>
            } />
            <Route path="/accounts" element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            } />
            <Route path="/account-type" element={
              <PrivateRoute>
                <AccountType />
              </PrivateRoute>
            } />

            {/* Catch-all route to redirect any undefined route to dashboard */}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
