import React, { useState } from 'react';
import { Nav, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTachometerAlt, 
  faBuilding, 
  faUsers, 
  faList, 
  faWarehouse, 
  faMoneyCheckAlt, 
  faChartBar, 
  faSignOutAlt, 
  faUser,
  faTags,
  faListAlt,
  faFileInvoiceDollar,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearSession } from '../../utils/sessionUtils';
import logo from '../static/images/logo.png';

export const SideNavbar = () => {
  const location = useLocation(); // Get the current URL path
  const navigate = useNavigate(); // Get the navigation function
  const [accountOpen, setAccountOpen] = useState(false); // State to handle collapse

  const handleLogout = () => {
    clearSession(); // Clear the user session
    navigate('/user/login'); // Redirect to the login page
  };

  return (
    <div className="side-navbar">
      <div className="navbar-logo">
        <div className="logo">
          <img src={logo} alt="SK ELECTRONICS" />
        </div>
        <hr />
      </div>
      <Nav className="flex-column">
        <Nav.Link href="/dashboard" active={location.pathname === "/dashboard"}>
          <FontAwesomeIcon icon={faTachometerAlt} /> Dashboard
        </Nav.Link>
        <Nav.Link href="/category" active={location.pathname === "/category"}>
          <FontAwesomeIcon icon={faTags} /> Product Category
        </Nav.Link>
        <Nav.Link href="/product" active={location.pathname === "/product"}>
          <FontAwesomeIcon icon={faBuilding} /> Product
        </Nav.Link>
        <Nav.Link href="/company" active={location.pathname === "/company"}>
          <FontAwesomeIcon icon={faBuilding} /> Company
        </Nav.Link>
        <Nav.Link href="/warehouse" active={location.pathname === "/warehouse"}>
          <FontAwesomeIcon icon={faWarehouse} /> Warehouse
        </Nav.Link>
        <Nav.Link href="/customer" active={location.pathname === "/customer"}>
          <FontAwesomeIcon icon={faUsers} /> Customer
        </Nav.Link>
        
        {/* Account Main Navbar */}
        <Nav.Link 
          onClick={() => setAccountOpen(!accountOpen)} 
          aria-controls="account-collapse"
          aria-expanded={accountOpen}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <span>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> Accounts
          </span>
          <FontAwesomeIcon 
            icon={faChevronDown} 
            style={{ transform: accountOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} 
          />
        </Nav.Link>
        
        {/* Collapsible Sub Navbar */}
        <Collapse in={accountOpen}>
          <div id="account-collapse">
            <Nav className="flex-column ml-3">
              <Nav.Link href="/account-category" active={location.pathname === "/account-category"}>
                <FontAwesomeIcon icon={faTags} /> L1
              </Nav.Link>
              <Nav.Link href="/account-sub-category" active={location.pathname === "/account-sub-category"}>
                <FontAwesomeIcon icon={faListAlt} /> L2
              </Nav.Link>
              <Nav.Link href="/account-type" active={location.pathname === "/account-type"}>
                <FontAwesomeIcon icon={faTags} /> Account Type
              </Nav.Link>
              <Nav.Link href="/accounts" active={location.pathname === "/accounts"}>
                <FontAwesomeIcon icon={faFileInvoiceDollar} /> Accounts
              </Nav.Link>
            </Nav>
          </div>
        </Collapse>

        <Nav.Link href="/reports" active={location.pathname === "/reports"}>
          <FontAwesomeIcon icon={faChartBar} /> Reports
        </Nav.Link>
        <div className="mt-auto">
          <Button variant="outline-success" className="logout-button" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </Button>
        </div>
      </Nav>
    </div>
  );
};