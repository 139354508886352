// src/components/withSessionCheck.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSessionValid, refreshSession } from '../utils/sessionUtils';

const withAuth = (WrappedComponent) => {
    return (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            const checkSession = () => {
                if (!isSessionValid()) {
                    navigate('/login');
                } else {
                    refreshSession();
                }
            };

            checkSession();
            const intervalId = setInterval(checkSession, 60000); // Check every minute

            return () => clearInterval(intervalId);
        }, [navigate]);

        return <WrappedComponent {...props} />;
    };
};

export default withAuth;