// src/utils/sessionUtils.js

export const isSessionValid = () => {
    const authToken = localStorage.getItem('authToken');
    const expirationTime = localStorage.getItem('sessionExpiration');

    if (!authToken || !expirationTime) {
        return false;
    }

    const currentTime = new Date().getTime();
    return currentTime < parseInt(expirationTime);
};

export const refreshSession = () => {
    const expirationTime = new Date().getTime() + (20 * 60 * 1000); // 20 minutes from now
    localStorage.setItem('sessionExpiration', expirationTime.toString());
};

export const clearSession = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('sessionExpiration');
};