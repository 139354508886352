import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Card, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import ConfirmationModal from './ConfirmationModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    companyName: '',
    companyEmail: '',
    companyPhoneNumber: '',
    companyAddress: '',
    companyDescription: '',
    companyStatus: '0' // Default to "Active"
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (showToast) {
      let timer;
      if (toastType === 'success') {
        timer = setTimeout(() => setShowToast(false), 2000);
        setProgress(100);
      } else if (toastType === 'danger') {
        timer = setTimeout(() => setShowToast(false), 5000);
        setProgress(100);
      }

      return () => clearTimeout(timer);
    }
  }, [showToast, toastType]);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/company/list-companies`);
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setToastMessage('Error fetching companies');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedCompanyId(id);
    setModalBody('Are you sure you want to delete this company?');
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmationModal(false);
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/company/${selectedCompanyId}`);
      if (response.status === 200) {
        setToastMessage(response.data.message);
        setToastType('success');
        setShowToast(true);
        fetchCompanies(); // Refresh companies after deletion
      } else {
        setToastMessage(response.data.message);
        setToastType('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error deleting company:', error);
      setToastMessage('Error deleting company');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateOrUpdateCompany = async () => {
    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${config.API_BASE_URL}/company/${selectedCompanyId}`, formData);
        if (response.status === 200) {
          setToastMessage(response.data.message);
          setToastType('success');
          setShowToast(true);
        } else {
          setToastMessage(response.data.message);
          setToastType('danger');
          setShowToast(true);
        }
      } else {
        response = await axios.post(`${config.API_BASE_URL}/company`, formData);
        if (response.status === 200 || response.status === 201) {
          setToastMessage(response.data.message);
          setToastType('success');
          setShowToast(true);
          fetchCompanies(); // Refresh companies after creation
          resetModal(); // Reset modal entries
        } else {
          setToastMessage(response.data.message);
          setToastType('danger');
          setShowToast(true);
        }
      }
  
      if (response.status === 200 || response.status === 201) {
        // Only close the modal if the operation was successful
        setShowEditModal(false);
      }
  
      fetchCompanies(); // Refresh companies after update or creation
    } catch (error) {
      console.error('Error creating or updating account:', error);
    
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setToastMessage(error.response.data.message);
        setToastType('danger');
        setShowToast(true);
      } else if (error.request) {
        // The request was made but no response was received
        setToastMessage('No response received from server');
        setToastType('danger');
        setShowToast(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setToastMessage('Error creating or updating company: ' + error.message);
        setToastType('danger');
        setShowToast(true);
      }
    }
  };
  
    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleEditClick = (company) => {
      setFormData({
        companyName: company.companyName,
        companyEmail: company.companyEmail,
        companyPhoneNumber: company.companyPhoneNumber,
        companyAddress: company.companyAddress,
        companyDescription: company.companyDescription,
        companyStatus: company.companyStatus.toString()
      });
      setSelectedCompanyId(company.id);
      setIsEditing(true);
      setShowEditModal(true);
    };
  
    const handleNewClick = () => {
      resetModal();
      setIsEditing(false);
      setShowEditModal(true);
    };
  
    const resetModal = () => {
      setFormData({
        companyName: '',
        companyEmail: '',
        companyPhoneNumber: '',
        companyAddress: '',
        companyDescription: '',
        companyStatus: '0' // Default to "Active"
      });
      setSelectedCompanyId(null);
    };
  
    // Filter companies based on search term
    const filteredCompanies = companies.filter(company =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    return (
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col-12 d-flex justify-content-between align-items-center top-header">
            <h4>Company</h4>
            <div className="icon-container">
              <Dropdown align="end" className="user-dropdown">
                <Dropdown.Toggle as="div" className="user-icon">
                  <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="3">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <FontAwesomeIcon icon={faCog} size="lg" className="icon ml-3" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <Form.Control
            type="text"
            placeholder="Search companies..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-75"
          />
          <Button variant="success" onClick={handleNewClick} className="ml-3">
            <FontAwesomeIcon icon={faPlus} /> New
          </Button>
        </div>
        <Card>
          <Card.Body>
            {filteredCompanies.length > 0 ? (
              <Table bordered striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Company</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Status</th>
                    <th>Tools</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCompanies.map((company, index) => (
                    <tr key={company.id}>
                      <td className="center">{index + 1}</td>
                      <td className="center">{company.companyName}</td>
                      <td className="center">{company.companyEmail}</td>
                      <td className="center">{company.companyPhoneNumber}</td>
                      <td className="center">{company.companyAddress}</td>
                      <td>
                        <Button className={`btn btn-sm ${company.companyStatus === 0 ? 'btn-success' : 'btn-danger'}`}>
                          {company.companyStatus === 0 ? 'Active' : 'Inactive'}
                        </Button>
                      </td>
                      <td>
                        <Button
                          className="btn btn-primary btn-sm ml-2"
                          onClick={() => handleEditClick(company)}
                        >
                          <i className="fa fa-edit" /> Edit
                        </Button>
                        <Button
                          className="btn btn-danger btn-sm ml-2"
                          onClick={() => handleDeleteClick(company.id)}
                        >
                          <i className="fa fa-trash" /> Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="text-center">No data found</p>
            )}
          </Card.Body>
        </Card>
        {/* Edit Company Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Company' : 'Add New Company'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleCreateOrUpdateCompany(); }}>
            <Form.Group controlId="companyName">
              <Form.Control
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="companyEmail" className="mt-3">
              <Form.Control
                type="email"
                name="companyEmail"
                placeholder="Company Email"
                value={formData.companyEmail}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="companyPhoneNumber" className="mt-3">
              <Form.Control
                type="text"
                name="companyPhoneNumber"
                placeholder="Company Phone Number"
                value={formData.companyPhoneNumber}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="companyAddress" className="mt-3">
              <Form.Control
                type="text"
                name="companyAddress"
                placeholder="Company Address"
                value={formData.companyAddress}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="companyDescription" className="mt-3">
              <Form.Control
                as="textarea"
                name="companyDescription"
                placeholder="Company Description"
                value={formData.companyDescription}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="companyStatus" className="mt-3">
              <Form.Control
                as="select"
                name="companyStatus"
                value={formData.companyStatus}
                onChange={handleFormChange}
                required
              >
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
            <div className="mt-3 d-flex justify-content-end gap-2">
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
              <Button variant="success" type="submit">{isEditing ? 'Save' : 'Create'}</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmDelete}
        modalBody={modalBody} // Pass the dynamic modal body content
      />
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
        {showToast && (
          <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {toastMessage}
              </div>
              <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
            </div>
            <div className="progress" style={{ height: '5px' }}>
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Company;