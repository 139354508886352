import React from 'react';
import { Chart } from 'react-google-charts';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser } from '@fortawesome/free-solid-svg-icons';
import withAuth from './withAuth';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../App.css'; // Import your CSS file

const Dashboard = () => {
  const salesData = [
    ['Element', 'Density', { role: 'style' }],
    ['Total Sales Today', 1000, 'color: #76A7FA'],
    ['Total Items Purchased Today', 400, 'color: #76A7FA'],
    ['Total Sale Products', 700, 'color: #76A7FA']
  ];

  return (
    <div className="container">
      <div className="row mb-2 mt-2">
        <div className="col-12 d-flex justify-content-between align-items-center top-header">
          <h4>Dashboard</h4>
          <div className="icon-container">
            <Dropdown alignRight className="user-dropdown">
              <Dropdown.Toggle as="div" className="user-icon">
                <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon icon={faCog} size="lg" className="icon" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={salesData}
            options={{ title: 'Sales Data' }}
          />
        </div>
      </div>
    </div>
  );
};

export default withAuth(Dashboard);
