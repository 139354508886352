const config = {
  development: {
    API_BASE_URL: 'http://localhost:8080',
  },
  production: {
    API_BASE_URL: 'https://admin.saleemkhanelectronics.com',
  },
};

const environment = process.env.NODE_ENV || 'production';

export default config[environment];
