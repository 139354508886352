import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Card, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser, faPlus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../config';
import ConfirmationModal from './ConfirmationModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Product = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]); // State to hold category options
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    productName: '',
    productCategory: '',
    productDescription: '',
    price: '',
    status: '0' // Default to "Active"
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchCategories(); // Fetch categories on component mount
  }, []);

  useEffect(() => {
    if (showToast) {
      let timer;
      if (toastType === 'success') {
        timer = setTimeout(() => setShowToast(false), 2000);
        setProgress(100);
      } else if (toastType === 'danger') {
        timer = setTimeout(() => setShowToast(false), 5000);
        setProgress(100);
      }

      return () => clearTimeout(timer);
    }
  }, [showToast, toastType]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/product/list-products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setToastMessage('Error fetching products');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/category/list-categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setToastMessage('Error fetching categories');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedProductId(id);
    setModalBody('Are you sure you want to delete this product?');
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmationModal(false);
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/product/${selectedProductId}`);
      if (response.status === 200) {
        setToastMessage(response.data.message);
        setToastType('success');
        setShowToast(true);
        fetchProducts(); // Refresh products after deletion
      } else {
        setToastMessage(response.data.message);
        setToastType('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      setToastMessage('Error deleting product');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateOrUpdateProduct = async () => {
    try {
      let response;
      if (isEditing) {
        response = await axios.put(`${config.API_BASE_URL}/product/${selectedProductId}`, formData);
        if (response.status === 200) {
          setToastMessage(response.data.message);
          setToastType('success');
          setShowToast(true);
        } else {
          setToastMessage(response.data.message);
          setToastType('danger');
          setShowToast(true);
        }
      } else {
        response = await axios.post(`${config.API_BASE_URL}/product`, formData);
        if (response.status === 200 || response.status === 201) {
          setToastMessage(response.data.message);
          setToastType('success');
          setShowToast(true);
          fetchProducts(); // Refresh products after creation
          resetModal(); // Reset modal entries
        } else {
          setToastMessage(response.data.message);
          setToastType('danger');
          setShowToast(true);
        }
      }

      if (response.status === 200 || response.status === 201) {
        // Only close the modal if the operation was successful
        setShowEditModal(false);
      }

      fetchProducts(); // Refresh products after update or creation
    } catch (error) {
      console.error('Error creating or updating account:', error);
    
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setToastMessage(error.response.data.message);
        setToastType('danger');
        setShowToast(true);
      } else if (error.request) {
        // The request was made but no response was received
        setToastMessage('No response received from server');
        setToastType('danger');
        setShowToast(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setToastMessage('Error creating or updating product.: ' + error.message);
        setToastType('danger');
        setShowToast(true);
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = (product) => {
    setFormData({
      productName: product.productName,
      productCategory: product.productCategory.toString(),
      productDescription: product.productDescription,
      price: product.price,
      status: product.status.toString()
    });
    setSelectedProductId(product.id);
    setIsEditing(true);
    setShowEditModal(true);
  };

  const handleNewClick = () => {
    resetModal();
    setIsEditing(false);
    setShowEditModal(true);
  };

  const resetModal = () => {
    setFormData({
      productName: '',
      productCategory: '',
      productDescription: '',
      price: '',
      status: '0' // Default to "Active"
    });
    setSelectedProductId(null);
  };

  // Filter products based on search term
  const filteredProducts = products.filter(product =>
    product.productName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mt-4">
      <div className="row mb-3">
        <div className="col-12 d-flex justify-content-between align-items-center top-header">
          <h4>Product</h4>
          <div className="icon-container">
            <Dropdown align="end" className="user-dropdown">
              <Dropdown.Toggle as="div" className="user-icon">
                <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon icon={faCog} size="lg" className="icon ml-3" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <Form.Control
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-75"
        />
        <Button variant="success" onClick={handleNewClick} className="ml-3">
          <FontAwesomeIcon icon={faPlus} /> New
        </Button>
      </div>
      <Card>
        <Card.Body>
          {filteredProducts.length > 0 ? (
            <Table bordered striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Tools</th>
                </tr>
              </thead>
              <tbody>
                {filteredProducts.map((product, index) => (
                  <tr key={product.id}>
                    <td className="center">{index + 1}</td>
                    <td className="center">{product.productName}</td>
                    <td className="center">{product.categoryName}</td>
                    <td className="center">{product.productDescription}</td>
                    <td className="center">{product.price}</td>
                    <td>
                      <Button className={`btn btn-sm ${product.status === 0 ? 'btn-success' : 'btn-danger'}`}>
                        {product.status === 0 ? 'Active' : 'Inactive'}
                      </Button>
                    </td>
                    <td>
                    <Button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => handleEditClick(product)}
                      >
                        <i className="fa fa-edit" /> Edit
                      </Button>
                      <Button
                        className="btn btn-danger btn-sm ml-2"
                        onClick={() => handleDeleteClick(product.id)}
                      >
                        <i className="fa fa-trash" /> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">No data found</p>
          )}
        </Card.Body>
      </Card>
      {/* Edit Product Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Product' : 'Add New Product'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleCreateOrUpdateProduct(); }}>
            <Form.Group controlId="productName">
              <Form.Control
                type="text"
                name="productName"
                placeholder="Product Name"
                value={formData.productName}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="productCategory" className="mt-3">
            <Form.Control
                as="select"
                name="productCategory"
                value={formData.productCategory}
                onChange={handleFormChange}
                required
                className="custom-select"
            >
                <option value="">Select Category</option>
                {categories.map(category => (
                <option key={category.id} value={category.id}>
                    {category.categoryName}
                </option>
                ))}
            </Form.Control>
            </Form.Group>
            <Form.Group controlId="productDescription" className="mt-3">
              <Form.Control
                as="textarea"
                name="productDescription"
                placeholder="Product Description"
                value={formData.productDescription}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="price" className="mt-3">
              <Form.Control
                type="number"
                name="price"
                placeholder="Price"
                value={formData.price}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status" className="mt-3">
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleFormChange}
                required
              >
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
            <div className="mt-3 d-flex justify-content-end gap-2">
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
              <Button variant="success" type="submit">{isEditing ? 'Save' : 'Create'}</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmDelete}
        modalBody={modalBody} // Dynamic modal body content
      />
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
        {showToast && (
          <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {toastMessage}
              </div>
              <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
            </div>
            <div className="progress" style={{ height: '5px' }}>
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;