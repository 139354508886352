import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Card, Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import ConfirmationModal from './ConfirmationModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    categoryName: '',
    categoryDescription: '',
    categoryStatus: '0' // Default to "Active"
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (showToast) {
      let timer;
      if (toastType === 'success') {
        timer = setTimeout(() => setShowToast(false), 2000);
        setProgress(100);
      } else if (toastType === 'danger') {
        timer = setTimeout(() => setShowToast(false), 5000);
        setProgress(100);
      }

      return () => clearTimeout(timer);
    }
  }, [showToast, toastType]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/category/list-categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setToastMessage('Error fetching categories');
      setToastType('danger');
      setShowToast(true);
    }
  };

  const handleDeleteClick = (id) => {
    setSelectedCategoryId(id);
    setModalBody('Are you sure you want to delete this category?');
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmationModal(false);
    try {
      const response = await axios.delete(`${config.API_BASE_URL}/category/${selectedCategoryId}`);
      if (response.status === 200) {
        setToastMessage(response.data.message);
        setToastType('success');
        setShowToast(true);
        fetchCategories(); // Refresh categories after deletion
      } else {
        setToastMessage(response.data.message);
        setToastType('danger');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error creating or updating account:', error);
    
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setToastMessage(error.response.data.message);
        setToastType('danger');
        setShowToast(true);
      } else if (error.request) {
        // The request was made but no response was received
        setToastMessage('No response received from server');
        setToastType('danger');
        setShowToast(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setToastMessage('Error creating or updating category. : ' + error.message);
        setToastType('danger');
        setShowToast(true);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCreateOrUpdateCategory = async () => {
    try {
      if (isEditing) {
        const response = await axios.put(`${config.API_BASE_URL}/category/${selectedCategoryId}`, formData);
        if (response.status === 200) {
          setToastMessage('Category updated successfully');
          setToastType('success');
          setShowToast(true);
        } else {
          setToastMessage(response.data.message);
          setToastType('danger');
          setShowToast(true);
        }
      } else {
        const response = await axios.post(`${config.API_BASE_URL}/category`, formData);
        if (response.status === 200 || response.status === 201) {
          setToastMessage('Category created successfully');
          setToastType('success');
          setShowToast(true);
          fetchCategories(); // Refresh categories after creation
          resetModal(); // Reset modal entries
        }
      }
      fetchCategories(); // Refresh categories after update or creation
    } catch (error) {
      console.error('Error creating or updating account:', error);
    
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setToastMessage(error.response.data.message);
        setToastType('danger');
        setShowToast(true);
      } else if (error.request) {
        // The request was made but no response was received
        setToastMessage('No response received from server');
        setToastType('danger');
        setShowToast(true);
      } else {
        // Something happened in setting up the request that triggered an Error
        setToastMessage('Error creating or updating category. : ' + error.message);
        setToastType('danger');
        setShowToast(true);
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditClick = (category) => {
    setFormData({
      categoryName: category.categoryName,
      categoryDescription: category.categoryDescription,
      categoryStatus: category.categoryStatus.toString()
    });
    setSelectedCategoryId(category.id);
    setIsEditing(true);
    setShowEditModal(true);
  };

  const handleNewClick = () => {
    resetModal();
    setIsEditing(false);
    setShowEditModal(true);
  };

  const resetModal = () => {
    setFormData({
      categoryName: '',
      categoryDescription: '',
      categoryStatus: '0' // Default to "Active"
    });
    setSelectedCategoryId(null);
  };

  // Filter categories based on search term
  const filteredCategories = categories.filter(category =>
    category.categoryName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 d-flex justify-content-between align-items-center top-header">
          <h4>Category</h4>
          <div className="icon-container">
            <Dropdown align="end" className="user-dropdown">
              <Dropdown.Toggle as="div" className="user-icon">
                <FontAwesomeIcon icon={faUser} size="lg" className="icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
                <Dropdown.Item eventKey="2">Change Password</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="3">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <FontAwesomeIcon icon={faCog} size="lg" className="icon ml-3" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3 ">
        <Form.Control
          type="text"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-75"
        />
        <Button variant="success" onClick={handleNewClick} className="ml-3">
          <FontAwesomeIcon icon={faPlus} /> New
        </Button>
      </div>
      <Card>
        <Card.Body>
          {filteredCategories.length > 0 ? (
            <Table bordered striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Tools</th>
                </tr>
              </thead>
              <tbody>
                {filteredCategories.map((category, index) => (
                  <tr key={category.id}>
                    <td className="center">{index + 1}</td>
                    <td className="center">{category.categoryName}</td>
                    <td>
                      <Button className={`btn btn-sm ${category.categoryStatus === 0 ? 'btn-success' : 'btn-danger'}`}>
                        {category.categoryStatus === 0 ? 'Active' : 'Inactive'}
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => handleEditClick(category)}
                      >
                        <i className="fa fa-edit" /> Edit
                      </Button>
                      <Button
                        className="btn btn-danger btn-sm ml-2"
                        onClick={() => handleDeleteClick(category.id)}
                      >
                        <i className="fa fa-trash" /> Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">No data found</p>
          )}
        </Card.Body>
      </Card>
      {/* Edit Category Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Edit Category' : 'Add New Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleCreateOrUpdateCategory(); }}>
            <Form.Group controlId="categoryName">
              <Form.Control
                type="text"
                name="categoryName"
                placeholder="Category Name"
                value={formData.categoryName}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="categoryDescription" className="mt-3">
              <Form.Control
                as="textarea"
                name="categoryDescription"
                placeholder="Category Description"
                value={formData.categoryDescription}
                onChange={handleFormChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="categoryStatus" className="mt-3">
              <Form.Control
                as="select"
                name="categoryStatus"
                value={formData.categoryStatus}
                onChange={handleFormChange}
                required
              >
                <option value="0">Active</option>
                <option value="1">Inactive</option>
              </Form.Control>
            </Form.Group>
            <div className="mt-3 d-flex justify-content-end gap-2">
              <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
              <Button variant="success" type="submit"  onClick={() => setShowEditModal(false)}>{isEditing ? 'Save' : 'Create'}</Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={handleConfirmDelete}
        modalBody={modalBody} // Pass the dynamic modal body content
      />
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1050 }}>
        {showToast && (
          <div className={`toast align-items-center text-bg-${toastType} border-0 show`} role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
                {toastMessage}
              </div>
              <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShowToast(false)}></button>
            </div>
            <div className="progress" style={{ height: '5px' }}>
              <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Category;
